.box {
  width: 80vw;
  height: auto;
  box-shadow: 0.5vw 0.5vw grey;
  position: relative;
  z-index: -1;
  margin-top: -26vw;
}

.box-intro {
  @extend .box;
  margin-top: 0;
  height: 80vw;
  position: relative;
  top: -25vw;
}

.portfolio {
  font-size: 3rem;
  font-family: $lato;
  font-style: italic;
  text-transform: uppercase;
}

.header {
  font-weight: bold;
  font-size: 3rem;
  text-shadow: 0.3vw 0.3vw grey;
  transform: translate(-10vw, -6vw);
}

.header-intro {
  @extend .header;
  position: relative;
  top: -29vw;
  left: -10vw;
  transform: translate(0,0);
}

.box-skills {
  @extend .box;
  margin-top: -24vw;
}

.intro-text {
  // font-weight: bold;
  font-size: 1rem;
  font-style: italic;
  // text-shadow: 0.3vw 0.3vw grey;
  // margin-top: 20vw;
}

.skills-header {
  @extend .header;
  margin-top: 10vw;
}

.about-text {
  @extend .portfolio;
  font-size: 0.8rem;
  transform: translate(-3vw)
}

.arrow {
  font-size: 2rem;
}

.skills-text {
  // @extend .portfolio;
  font-family: $lato;
  font-style: italic;
  text-transform: uppercase;
  // font-size: 0.9rem;
  // transform: translate(0);
}

.sub-text {
  font-size: 0.6rem;
}

// .arrows {
//   margin-top: -20vw;
// }
