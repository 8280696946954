.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrow-down {
  font-size: 7rem;
  color: white;
}

.header-download {
  width: 45vw;
  margin-top: auto;
  color: white;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
  font-size: 2.5rem;

  span {
    font-weight: 800;
    font-size: 4rem;
  }

}

a:hover {
  text-decoration: none !important;
}
